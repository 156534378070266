import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import CountdownContext from "src/contexts/CountdownContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import formatCurrency from "src/utils/formatCurrency";
import { NumericOnly } from "src/utils/numericOnly";
import { notifyError, notifySuccess } from "src/utils/toast";
import useSound from "use-sound";
import useApiData from "../../../hooks/useApiData";
import TimeframeSelect from "../utils/TimeframeSelect";

import { CandlestickChart } from "@mui/icons-material";
import { CgTimelapse } from "react-icons/cg";
import { useStompClient } from "react-stomp-hooks";
import orderClick from "src/assets/audio/notifications/mixkit-cool-interface-click-tone-2568.wav";
import { InputAdornmentIcon } from "src/components/InputAdornmentIcon";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import AccountContext from "src/contexts/AccountContext";
import { IOperationMode } from "src/contexts/ApiContext";
import SettingsContext from "src/contexts/SettingsContext";
import useOrderBook from "src/hooks/useOrderBook";
import useAvailableSymbols from "src/swr/use-available-symbols";
import { OrderBookItem } from "src/types/order";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import { isValidOrder } from "src/utils/isValidOrder";
import { useLocalStorage } from "usehooks-ts";

const useStyles = {
  "& .MuiFormLabel-root": {
    textAlign: "center",
    color: "#919eab !important",
    fontSize: "1rem",
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    color: "#CCC",
  },
  "& .MuiInputBase-root": {
    fontSize: "1rem",
    background: "transparent",
  },
  svg: {
    color: "#475760",
    cursor: "pointer",
  },
};

const PlaceOrderForm: FC = () => {
  const { t } = useTranslation("dashboard");
  const stompClient = useStompClient();

  const { parameters } = useParameters();
  let localStorageAmoun = localStorage.getItem("localStorageAmount");
  const [amount, setAmount] = useState<string>(
    localStorageAmoun ? localStorageAmoun : "0"
  );
  const [balance, setBalance] = useState(0);
  const [playOrderClick] = useSound(orderClick);
  const { bookRed, bookGreen } = useOrderBook();
  const [purchase, setPurchase] = useState(0);
  const [sale, setSale] = useState(0);
  const [afterFirstRender, setAfterFirstRender] = useState(false);

  const {
    payout,
    user,
    operationMode,
    updateOperationMode,
    selectedCandle,
    selectedDatatime,
    userBalance,
    orderValue,
    setOrderValue,
    handleSingleUserOrder,
    handleUserOrdersEvent,
    handleRemoveLiveOperationsOrder,
    userLiveOperations,
    updatePayout,
  } = useApiData();
  const { activeAccount } = useContext(AccountContext);

  const {
    tvWidget,
    selectedSymbol,
    ordersOpened,
    ordersPending,
    removeOrderOpenedById,
  } = useContext(TradingViewContext);
  const { count, countValue, timeFormat } = useContext(CountdownContext);
  const { settings } = useContext(SettingsContext);
  const [, setCurrentTab] = useLocalStorage<string>("currentTab", "order_book");
  const lastBar = getLastBar(selectedSymbol, "PlaceOrderForm");
  const { symbols, selectedSymbolData } = useAvailableSymbols();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile2 = useMediaQuery(theme.breakpoints.down("md"));

  const isNewOptionsTab = operationMode === "OPTION";

  const isFeatureTest = true;

  useEffect(() => {
    if (user && !isFeatureTest) {
      updateOperationMode("OPTION");
    }
  }, [user, isFeatureTest]);

  useEffect(() => {
    if (localStorageAmoun) {
      increaseAmount(Number(localStorageAmoun));
    } else {
      increaseAmount(orderValue);
    }

    setAfterFirstRender(true);
  }, []);

  useEffect(() => {
    if (ordersOpened.length > 0 || ordersPending.length > 0) {
      // Atualizar apenas as ordens abertas
      ordersOpened.forEach((order) => {
        const invalidOpenOrder = !isValidOrder(order.order);

        if (invalidOpenOrder) {
          if (order.orderTradingView) {
            removeTradingviewOrder(order.orderId, order.orderTradingView);
          }
        } else {
          updateOrderResultOpened({
            order: order.order,
            orderTradingView: order.orderTradingView,
            totalOrderValue: order.totalOrderValue,
          });
        }
      });

      // Atualizar apenas as ordens pendentes
      ordersPending.forEach((order) => {
        const invalidPendingOrder = !isValidOrder(order.order);

        if (invalidPendingOrder) {
          if (order.orderTradingView) {
            removeTradingviewOrder(order.orderId, order.orderTradingView);
          }
        } else {
          updateOrderResultOpened({
            order: order.order,
            orderTradingView: order.orderTradingView,
            totalOrderValue: order.order.accept,
            isPending: true,
          });
        }
      });
    }
  }, [timeFormat, ordersOpened, ordersPending, parameters]);

  useEffect(() => {
    Object.keys(userLiveOperations).forEach((key) => {
      const order = userLiveOperations[key];
      const invalidPendingOrder = !isValidOrder(order);
      if (invalidPendingOrder) {
        handleRemoveLiveOperationsOrder(key);
      }
    });
  }, [timeFormat, userLiveOperations]);

  // Função de atualização otimizada
  const updateOrderResultOpened = useCallback(
    ({
      order,
      orderTradingView,
      totalOrderValue = 0,
      isPending = false,
    }: {
      order: any;
      orderTradingView: any;
      totalOrderValue: number;
      isPending?: boolean;
    }) => {
      // Cálculo centralizado de estimateIncome
      const isNewOption = order.binaryOrderType === "OPTION";
      const amount = totalOrderValue || 0;
      let estimateIncome = parseFloat((amount * (payout / 100)).toFixed(3));

      // Obter as cores e o símbolo baseado no resultado da função getResulteOnLive
      const { color, simbol, status } = getResulteOnLive({
        order,
        payout,
        lastDailyBar: lastBar,
      });

      // Formatação do resultado
      const numericResult = parseFloat(estimateIncome.toFixed(2));
      const formattedResult = formatCurrency(
        simbol === "+" ? numericResult : amount
      );

      const label = `${simbol}${formattedResult}`;

      // Adicionar try-catch para proteger contra tentativas de acessar um orderTradingView removido
      try {
        // Verificar se `orderTradingView` ainda existe antes de tentar acessá-lo
        if (
          orderTradingView &&
          typeof orderTradingView.setText === "function"
        ) {
          if (orderTradingView.getText() !== label && !isPending) {
            orderTradingView.setText(label);
          }

          if (isNewOption && orderTradingView.getQuantity() !== timeFormat) {
            orderTradingView.setQuantity(timeFormat);
          } else {
            orderTradingView.setQuantity(
              calculateCountdown(order.candleEndTime)
            );
          }

          if (orderTradingView.getLineColor() !== color) {
            orderTradingView.setBodyTextColor(color);
          }
        }
      } catch (error) {
        console.error("Erro ao atualizar a ordem:", error);
      }
    },
    [timeFormat, selectedSymbolData, payout]
  );

  const calculateCountdown = (timestamp: number): string => {
    const now = new Date().getTime();
    const distance = timestamp - now;

    if (distance < 0) {
      return "Ordem finalizada";
    } else {
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const removeTradingviewOrder = (orderId: string, orderTradingView: any) => {
    try {
      orderTradingView?.remove();
      removeOrderOpenedById(orderId);
    } catch (error) {
      console.error("Erro ao remover a ordem:");
    }
  };

  const increaseAmount = (orderValue?: number) => {
    console.log("orderValue", orderValue);
    if (orderValue) {
      setAmount(orderValue.toString());
      localStorage.setItem("localStorageAmount", orderValue.toString());
      return;
    }

    const newAmount = parseFloat(amount) + 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
    localStorage.setItem("localStorageAmount", newAmount.toString());
  };

  const decreaseAmount = () => {
    if (parseFloat(amount) < 2) return;
    const newAmount = parseFloat(amount) - 1;
    setOrderValue(newAmount);
    setAmount(newAmount.toString());
    localStorage.setItem("localStorageAmount", newAmount.toString());
  };

  const placeOrder = async (type: string): Promise<void> => {
    if (parseFloat(amount) > balance) {
      notifyError("Quantidade não disponível em carteira.");
      return;
    }

    try {
      const direction = type.toUpperCase() as any;
      const invest = parseFloat(amount);

      const lastBar = getLastBar(selectedSymbol, "PlaceOrderForm");
      const candleEndTime = selectedDatatime;

      console.log("candleEndTime", candleEndTime);

      const publishOrder = () => {
        if (stompClient) {
          stompClient.publish({
            destination: "/user/topic/execute",
            body: JSON.stringify({
              binaryOrderType: operationMode,
              accountId: activeAccount.id,
              candleTimeFrame: selectedCandle,
              candleEndTime,
              symbol: selectedSymbol,
              direction,
              invest,
              asset: parameters?.DEFAULT_COIN?.value.toUpperCase(),
              price: lastBar.close,
            }),
          });
        } else {
          setTimeout(publishOrder, 2000);
        }
      };

      publishOrder();
      setCurrentTab("operations");
    } catch (err) {
      if (err === "Locked window") {
        notifyError("Aguarde alguns segundos para fazer o envio da ordem");
        return;
      } else if (err === "SimultaneousRequestsException") {
        notifyError(
          "Várias ordens detectadas em sequência. Aguarde e tente novamente."
        );
        return;
      }
      notifyError("Oops! Não foi possível enviar a ordem.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NumericOnly(e)) {
      setAmount(e.target.value === "" ? "" : e.target.value);
      setOrderValue(parseFloat(e.target.value));
      localStorage.setItem("localStorageAmount", e.target.value);
    }
  };

  const handleBlur = () => {
    if (amount === "") {
      setAmount("1");
      setOrderValue(1);
    }
  };

  useEffect(() => {
    setBalance(userBalance);
  }, [setBalance, setAmount, userBalance]);

  const income = useMemo(() => {
    const numericAmount = parseFloat(amount);
    const estimateIncome = numericAmount * (payout / 100);
    return parseFloat(estimateIncome.toFixed(3));
  }, [amount, payout]);

  const temp1: OrderBookItem[] =
    bookGreen && bookGreen.length > 0
      ? bookGreen.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];
  const temp2: OrderBookItem[] =
    bookRed && bookRed.length > 0
      ? bookRed.filter((it) => it.candleTimeFrame === selectedCandle)[0]
          ?.orderBook || []
      : [];

  useEffect(() => {
    const totalPurchase = temp1.reduce((acc, curr) => acc + curr.invest, 0);
    const totalSale = temp2.reduce((acc, curr) => acc + curr.invest, 0);

    if (totalPurchase || totalSale) {
      const totalInvest = totalPurchase + totalSale;

      const purchasePercent = (totalPurchase / totalInvest) * 100;
      const salePercent = (totalSale / totalInvest) * 100;

      setPurchase(purchasePercent);
      setSale(salePercent);
    } else {
      setPurchase(50);
      setSale(50);
    }
  }, [temp1, temp2]);

  const handleOperationTabChange = (event, value: IOperationMode): void => {
    const eventPayload = {
      event: "user_orders",
      payload: {
        open: [],
        pending: [],
      },
    };

    handleUserOrdersEvent(eventPayload);
    stompClient.publish({
      destination: "/user/topic/data",
      body: JSON.stringify({
        type: "GET_ORDERS",
        data: { accountId: activeAccount.id },
      }),
    });
    console.log("value", value);
    updateOperationMode(value);

    if (isFeatureTest && afterFirstRender) {
      notifySuccess(
        value === "OPTION"
          ? "Você selecionou Novas Opções"
          : "Você selecionou retração"
      );
    }
  };

  const disableButton = countValue < (isNewOptionsTab ? 7 : 4);

  const isOptionActive =
    selectedSymbolData?.configModes?.OPTION?.status === "ACTIVE";
  const isRetractionActive =
    selectedSymbolData?.configModes?.RETRACTION_ENDTIME?.status === "ACTIVE";

  useEffect(() => {
    const selectedSymbolData = symbols.find(
      (symbol) => symbol.symbol === selectedSymbol
    );

    if (selectedSymbolData) {
      const { configModes } = selectedSymbolData;

      if (configModes[operationMode].status !== "ACTIVE") {
        const availableMode = Object.keys(configModes).find(
          (mode) => configModes[mode].status === "ACTIVE"
        );
        updatePayout(configModes[availableMode].payout);
        updateOperationMode((availableMode || "OPTION") as IOperationMode);
      } else {
        updatePayout(configModes[operationMode].payout);
      }
    }
  }, [selectedSymbol, symbols, operationMode]);

  return (
    <>
      {isFeatureTest && (
        <Box>
          <Tabs
            indicatorColor="primary"
            onChange={handleOperationTabChange}
            textColor="primary"
            value={operationMode}
            sx={{
              "& .MuiTabs-scroller": {
                height: "100%",
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
              },
              "& .MuiButtonBase-root": {
                "@media screen and (max-width: 768px)": {
                  flex: 1,
                },
                marginBottom: 1,
                textTransform: "capitalize",
                fontSize: ".875rem",
                minHeight: 32,
                height: 32,
                "&[aria-selected='true']": {
                  svg: {
                    color: "#00DB97",
                  },
                  color: "#fff",
                  transition: "all 0.4s ease",
                },
              },
              "& .MuiTabs-indicator": {
                height: 4,
                borderRadius: 20,
              },
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
            }}
          >
            <Tab
              icon={<CandlestickChart />}
              iconPosition="start"
              label={t("new_options")}
              value="OPTION"
              disabled={!isOptionActive}
            />

            <Tab
              icon={<CgTimelapse />}
              iconPosition="start"
              label={t("retraction")}
              value="RETRACTION_ENDTIME"
              disabled={!isRetractionActive}
            />
          </Tabs>
        </Box>
      )}

      <Box
        sx={{
          outline: "1px solid #0b1721",
          bgcolor: "#040c11",
          borderRadius: 1,
          px: isMobile ? 1 : 2,
          pt: isMobile && 2,
          pb: isMobile && 1,
          mt: !isMobile && 2,
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 8, md: 12 }}
          sx={useStyles}
        >
          <Grid
            item
            xs={isMobile ? 6 : 12}
            sx={{
              my: 0,
              flexDirection: isMobile ? "column-reverse" : "column",
              display: "flex",
            }}
          >
            <TimeframeSelect />
          </Grid>
          <Grid item xs={isMobile ? 6 : 12} sx={{ mb: isMobile ? 0.5 : 0 }}>
            <TextField
              size={isMobile ? "small" : "medium"}
              sx={{
                "& .MuiInputBase-root input": {
                  fontSize: isMobile ? "1rem" : "1.25rem",
                  padding: isMobile ? "8.5px 0" : "0.850rem 0",
                },
                "& .MuiIconButton-root:hover": {
                  background: "transparent",
                },
              }}
              value={amount}
              label={`${t("value")} (U$)`}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornmentIcon
                    onClick={() => decreaseAmount()}
                    icon={FaCircleMinus}
                  />
                ),
                endAdornment: (
                  <InputAdornmentIcon
                    position="end"
                    onClick={() => increaseAmount()}
                    icon={FaCirclePlus}
                  />
                ),
              }}
              inputProps={{
                style: { WebkitAppearance: "none" },
              }}
            />
            {isMobile && (
              <Stack
                // direction="column"
                // justifyContent="center"
                // spacing={2}
                // marginTop={0.7}
                direction="row"
                alignItems="center"
                justifyContent="start"
                pl={0.4}
                pt={0.4}
                gap={0.4}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "top",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      color="#606f79"
                      fontWeight={400}
                      variant="caption"
                      fontSize={amount.length > 4 ? "10px" : "12px"}
                    >
                      {t("revenue")}
                    </Typography>
                  </Box>
                  <Typography
                    color="#01DB97"
                    fontWeight={600}
                    variant="caption"
                    fontSize={amount.length > 4 ? "10px" : "14px"}
                    sx={{ lineHeight: 1 }}
                  >
                    {`+${formatCurrency(income)}`}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>

        <Grid item sx={{ my: isMobile ? 0 : 2, pb: isMobile ? 0 : "16px" }}>
          {!isMobile && (
            <Grid item xs={isMobile ? 12 : 6}>
              <Stack direction="column" justifyContent="center" spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                    paddingBottom: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <Typography
                      color="#606f79"
                      fontWeight={400}
                      variant="caption"
                      fontSize={".85rem"}
                    >
                      {t("revenue")}
                    </Typography>
                    {!isMobile && (
                      <Typography
                        color="#01DB97"
                        fontSize="1rem"
                        fontWeight={600}
                      >
                        +{`${payout}%`}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <Typography
                      color="#01DB97"
                      variant="caption"
                      fontSize={isMobile2 ? "1rem" : "2.2rem"}
                      sx={{
                        lineHeight: 1,
                        fontWeight: 500,
                        fontSize: amount.length >= 6 ? "2rem" : "2.2rem",
                      }}
                    >
                      {`+${formatCurrency(income)}`}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          )}

          {isMobile && (
            <Box
              className="box_container"
              style={{
                display: "flex",
                color: "#CCC",
                alignItems: "center",
                gap: "0.5rem",
                fontSize: "14px",
                paddingBottom: "0.5rem",
              }}
            >
              <span className="compra" style={{ display: "inline-block" }}>
                {purchase === 0 ? 0 : purchase.toFixed(0)}%
              </span>
              <span
                className="ativo_linha"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "4px",
                  position: "relative",
                  gap: "4px",
                }}
              >
                <span
                  className="compra_bar"
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `${purchase}%`,
                    backgroundColor: "rgb(27, 186, 135)",
                    transition: "width 1s ease",
                    borderRadius: "16px",
                  }}
                ></span>
                <span
                  className="venda_bar"
                  style={{
                    display: "inline-block",
                    height: "100%",
                    width: `${100 - purchase}%`,
                    backgroundColor: "rgb(255, 2, 92)",
                    transition: "width 1s ease",
                    borderRadius: "16px",
                  }}
                ></span>
              </span>
              <span className="venda" style={{ display: "inline-block" }}>
                {sale === 0 ? 0 : sale.toFixed(0)}%
              </span>
            </Box>
          )}

          <Stack
            direction={isMobile ? "row" : "column"}
            spacing={isMobile ? 2 : 1}
            justifyContent="space-between"
            alignContent={"end"}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                minHeight: isMobile ? 48 : 70,
                borderRadius: "8px",
                display: "flex",
                gap: "0.5rem",
                padding: isMobile ? "0" : null,
                "&:hover": {
                  backgroundColor: "#08865a",
                },
              }}
              onClick={() => placeOrder("bull")}
              disabled={parseInt(amount) === 0 || disableButton}
            >
              <Typography
                fontSize="0.875rem"
                fontWeight="600"
                textTransform="uppercase"
              >
                {t("bull")}
              </Typography>
              <TrendingUpIcon fontSize={isMobile ? "large" : "medium"} />
            </Button>

            {!isMobile && (
              <Box
                className="box_container"
                style={{
                  display: "flex",
                  color: "#CCC",
                  alignItems: "center",
                  gap: "0.5rem",
                  fontSize: "14px",
                }}
              >
                <span className="compra" style={{ display: "inline-block" }}>
                  {purchase === 0 ? 0 : purchase.toFixed(0)}%
                </span>
                <span
                  className="ativo_linha"
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "4px",
                    position: "relative",
                    gap: "4px",
                  }}
                >
                  <span
                    className="compra_bar"
                    style={{
                      display: "inline-block",
                      height: "100%",
                      width: `${purchase}%`,
                      backgroundColor: "rgb(27, 186, 135)",
                      transition: "width 1s ease",
                      borderRadius: "16px",
                    }}
                  ></span>
                  <span
                    className="venda_bar"
                    style={{
                      display: "inline-block",
                      height: "100%",
                      width: `${100 - purchase}%`,
                      backgroundColor: "rgb(255, 2, 92)",
                      transition: "width 1s ease",
                      borderRadius: "16px",
                    }}
                  ></span>
                </span>
                <span className="venda" style={{ display: "inline-block" }}>
                  {sale === 0 ? 0 : sale.toFixed(0)}%
                </span>
              </Box>
            )}

            <Button
              sx={{
                width: "100%",
                minHeight: isMobile ? 40 : 70,
                borderRadius: "8px",
                backgroundColor: "#FF025C",
                gap: "0.5rem",
                padding: isMobile ? "0" : null,
                "&:hover": {
                  backgroundColor: "#CC024D",
                },
              }}
              variant="contained"
              onClick={() => placeOrder("bear")}
              disabled={parseInt(amount) === 0 || disableButton}
            >
              <Typography
                fontSize="0.875rem"
                fontWeight="600"
                textTransform="uppercase"
              >
                {t("bear")}
              </Typography>
              <TrendingDownIcon fontSize={isMobile ? "large" : "medium"} />
            </Button>
          </Stack>
        </Grid>
      </Box>
    </>
  );
};

export default PlaceOrderForm;
