import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { BsCurrencyExchange } from "react-icons/bs";
import { FaBolt } from "react-icons/fa6";
import { IoIosStarOutline, IoMdClose, IoMdStar } from "react-icons/io";
import { SiBitcoinsv } from "react-icons/si";
import mobileLogoImage from "src/assets/images/x.svg";
import { symbols } from "src/constants";
import { IOperationMode } from "src/contexts/ApiContext";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useApiData from "src/hooks/useApiData";
import { favoriteSymbol } from "src/lib/api/users";
import useAvailableSymbols from "src/swr/use-available-symbols";
import useFavoritesSymbols from "src/swr/use-favorites-symbols";
import { SymbolFilterData } from "src/types/filter";
import { correctSymbol, updateCurrentChartAnalysis } from "src/utils";
import { notifyError } from "src/utils/toast";
import DefaultCoinChart from "../custom/coins/DefaultCoinChart";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  top: isMobile ? "8px" : "24px",
  "& .search-input ": {
    marginBottom: "1rem",

    "& .MuiInputBase-root": {
      borderRadius: "8px",
    },

    "& fieldset": {
      border: "none",
    },
  },
  "& .content": {
    display: "flex",
    height: isMobile ? "100vh" : "auto",
    flexDirection: isMobile ? "column" : "row",
  },
  "& .MuiList-root": {
    padding: 0,
  },
  "& .MuiPaper-root": {
    width: isMobile ? "100%" : null,
    borderRadius: "16px",
    border: "2px solid rgb(4, 15, 20)",
    background: "transparent",
    backdropFilter: "blur(10px)",
    top: "56px !important",
  },
  "& .left-side": {
    padding: isMobile ? "0.5rem" : "1rem",
    backgroundColor: "rgb(4 15 20 / 94%)",
  },
  "& ul.assets-options": {
    listStyle: "none",
    padding: 0,
    display: isMobile ? "flex" : "block",
    alignItems: isMobile ? "center" : null,
    justifyContent: isMobile ? "center" : null,
  },
  "& ul.assets-options .option": {
    padding: isMobile ? "0.5rem" : "1rem 1.5rem",
    display: "flex",
    alignItems: "center",
    gap: isMobile ? "0.5rem" : "1rem",
    fontSize: isMobile ? "0.875rem" : "1rem",
    cursor: "pointer",
    borderRadius: "8px",
    marginBottom: "0.5rem",

    "&:hover, &.active": {
      backgroundColor: "#319676",
    },

    "&:hover svg, &.active svg": {
      fill: "#FFF",
    },
  },
  "& ul.assets-options .option svg": {
    fill: "#fff",
  },
  "& .right-side": {
    width: isMobile ? "100%" : "650px",
    padding: isMobile ? "1rem" : "2rem",
    height: isMobile && "100vh",
    background: "rgb(1 8 13 / 95%)",
    position: "relative",
  },
  "& .right-side .btn-close": {
    position: "absolute",
    right: "1rem",
    top: "1rem",
    cursor: "pointer",
    color: "#26363d",

    "&:hover": {
      fill: "#FFF",
    },
  },
  "& .right-side .top-content": {
    display: "flex",
    flexDirection: "column",
  },
  "& .MuiTextField-root": {
    background: "transparent",
  },
  "& .right-side .body-content": {
    "& th": {
      color: "#AAA",
      fontSize: ".875rem",
      fontWeight: "600",
      borderBottom: "none",
      padding: "0.5rem 0",
    },
    "& tr td": {
      marginBottom: "4px",
      borderBottom: "1px solid #0a141a",
      padding: "0.5rem 0",
    },
    "& tr.symbols-table-row:hover": {
      cursor: "pointer",
      color: "#FFF",
      backgroundColor: "#030e12",
    },
    "& tr:last-child td": {
      borderBottom: "none",
    },
    "& td.lucro-cell span": {
      padding: "0.2rem 0.4rem",
      borderRadius: "6px",
      fontWidth: "900",
    },
    "& td.favorite-cell svg": {
      cursor: "pointer",
    },
    "& .close_symbol_tab": {
      cursor: "pointer",
      position: "absolute",
      right: "8px",
    },
  },
}));

export default function MenuSelectSymbol() {
  const { t } = useTranslation("dashboard");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { symbols: symbolsList } = useAvailableSymbols();
  const { mutate: mutateFavoritesSymbols } = useFavoritesSymbols();
  const { layout, setModalOutsideMarket, setLoadingTradingview } =
    React.useContext(LayoutContext);
  const { tvWidget, selectedSymbol, updateSymbol, removeOrdersBySymbol } =
    React.useContext(TradingViewContext);
  const { user, updateUserLiveOperations, updateOperationMode } = useApiData();
  const [activeSymbol, setActiveSymbol] = React.useState<any>({});
  const [currentSymbolType, setCurrentSymbolType] =
    React.useState<SymbolFilterData["symbolType"]>("FOREX");
  const [searchText, setSearchText] = React.useState("");
  const [selectedMenuSymbols, setSelectedMenuSymbols] = React.useState<any>([]);
  const [currentTab, setCurrentTab] = React.useState("OPTION");
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("default");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const symbolActive = symbols.find((s) => s.name === selectedSymbol);
    const symbolType = symbolActive?.type === "crypto" ? "CRYPTO" : "FOREX";
    setCurrentSymbolType(symbolType);
    setActiveSymbol(symbolActive);
  }, [selectedSymbol]);

  React.useEffect(() => {
    const storedSymbols = localStorage.getItem("selectedMenuSymbols");
    if (storedSymbols) {
      const parsedSymbols = JSON.parse(storedSymbols);
      setSelectedMenuSymbols(parsedSymbols);

      if (parsedSymbols.length === 0) {
        const foundSymbol = symbolsList.find(
          (item) => item.symbol === selectedSymbol
        );

        if (foundSymbol) {
          setSelectedMenuSymbols([foundSymbol]);
        }
      }
    } else {
      const foundSymbol = symbolsList.find(
        (item) => item.symbol === selectedSymbol
      );

      if (foundSymbol) {
        setSelectedMenuSymbols([foundSymbol]);
      }
    }
  }, [selectedSymbol, symbolsList]);

  const handleSwitchSymbol = (item: any, alterTab = false) => {
    try {
      if (item.marketStatus !== "OPEN") {
        setModalOutsideMarket(
          true,
          `O gráfico do índice ${item.symbol} está fechado no momento.`,
          false
        );
        return;
      }

      const symbolExists = selectedMenuSymbols.some(
        (i) => i.symbol === item.symbol
      );

      if (symbolExists && item.symbol === activeSymbol.name) {
        handleClose();
        if (alterTab) {
          updateOperationMode(currentTab as IOperationMode);
        }
        return;
      }

      updateUserLiveOperations([]);

      window.tvWidget.activeChart().setSymbol(item.symbol);

      const updatedSymbols = updateSelectedSymbols(item);

      setSelectedMenuSymbols(updatedSymbols);
      localStorage.setItem(
        "selectedMenuSymbols",
        JSON.stringify(updatedSymbols)
      );

      updateCurrentChartAnalysis(item.symbolLabel);
      updateSymbol(item.symbol);
      if (alterTab) {
        updateOperationMode(currentTab as IOperationMode);
      }
      removeOrdersBySymbol(item.symbol);

      handleClose();
      setSearchText("");
    } catch (error) {
      console.error("Failed to switch symbol", error);
      notifyError("Oops! Não foi possível efetuar a operação.");
      setLoadingTradingview(false);
    }
  };

  const updateSelectedSymbols = (item: any) => {
    const symbolExists = selectedMenuSymbols.some(
      (i) => i.symbol === item.symbol
    );

    if (symbolExists) {
      return selectedMenuSymbols;
    }

    const activeIndex = selectedMenuSymbols.findIndex(
      (i) => i.symbol === activeSymbol.name
    );

    let updatedSymbols;
    if (activeIndex !== -1) {
      updatedSymbols = [
        ...selectedMenuSymbols.slice(0, activeIndex),
        item,
        ...selectedMenuSymbols.slice(activeIndex),
      ];
    } else {
      updatedSymbols = [item, ...selectedMenuSymbols];
    }

    if (updatedSymbols.length > 5) {
      updatedSymbols = updatedSymbols.slice(0, 5);
    }

    return updatedSymbols;
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const getPayout = (item) => {
    const optionPayout = item.configModes[currentTab]?.payout || 0;
    return optionPayout;
  };

  const filteredRows = React.useMemo(() => {
    const lowerCaseSearchText = searchText.toLowerCase();

    return symbolsList
      .filter((item) => {
        const config = item.configModes[currentTab];

        return config.status === "ACTIVE";
      })
      .filter((item) => {
        const matchesSearchText = item.symbol
          .toLowerCase()
          .includes(lowerCaseSearchText);

        if (currentSymbolType === "FAVORITE") {
          return item.favorite && matchesSearchText;
        }

        return item.symbolType === currentSymbolType && matchesSearchText;
      });
  }, [searchText, symbolsList, currentSymbolType, currentTab]);

  const sortedRows = filteredRows.sort((a, b) => {
    if (orderBy === "profit") {
      const profitA = getPayout(a);
      const profitB = getPayout(b);
      if (order === "asc") {
        return profitA - profitB;
      } else {
        return profitB - profitA;
      }
    } else if (orderBy === "changes") {
      if (order === "asc") {
        return a.hrs24PercentualChange - b.hrs24PercentualChange;
      } else {
        return b.hrs24PercentualChange - a.hrs24PercentualChange;
      }
    } else if (orderBy === "symbol") {
      if (order === "asc") {
        return a.symbol.localeCompare(b.symbol);
      } else {
        return b.symbol.localeCompare(a.symbol);
      }
    }

    const indexA = symbolsList.findIndex(
      (symbol) => symbol.symbol === a.symbol
    );
    const indexB = symbolsList.findIndex(
      (symbol) => symbol.symbol === b.symbol
    );
    return indexA - indexB;
  });

  const handleCloseSymbolTab = (e, tab) => {
    e.stopPropagation();

    const updatedSymbols = selectedMenuSymbols.filter(
      (item) => item.symbol !== tab.symbol
    );

    if (tab.symbol === activeSymbol.name && updatedSymbols.length > 0) {
      updateUserLiveOperations([]);
      setLoadingTradingview(true);
      const nextActiveSymbol = getNextActiveSymbol(tab.symbol, updatedSymbols);
      updateCurrentChartAnalysis(tab.symbolLabel);
      updateSymbol(nextActiveSymbol.symbol);
      removeOrdersBySymbol(nextActiveSymbol.symbol);
      tvWidget.activeChart().setSymbol(nextActiveSymbol.symbol);
    }

    setSelectedMenuSymbols(updatedSymbols);
    localStorage.setItem("selectedMenuSymbols", JSON.stringify(updatedSymbols));
    localStorage.setItem("subscribeTimeframeStatus", "LOADING");
  };

  const getNextActiveSymbol = (removedSymbol, updatedSymbols) => {
    const removedIndex = selectedMenuSymbols.findIndex(
      (item) => item.symbol === removedSymbol
    );

    const nextIndex =
      removedIndex < updatedSymbols.length ? removedIndex : removedIndex - 1;

    return updatedSymbols[nextIndex];
  };

  const handleFavorite = async (symbol: string, favorite: boolean) => {
    try {
      await favoriteSymbol(symbol, favorite);
      mutateFavoritesSymbols();
    } catch (error) {
      notifyError("Oops! Não foi possível efetuar a operação.");
    }
  };

  return (
    <>
      {isMobile && (
        <Button
          variant="contained"
          sx={{
            px: isMobile ? 0.5 : undefined,
            mr: isMobile ? 1 : undefined,
            "& .MuiButton-endIcon": { ml: 0 },
            position: "relative",
            border: "1px solid rgb(19 149 108)",
            background: "#01160f",
            height: isMobile ? "3rem" : "3.5rem",
            borderRadius: "8px",
          }}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          disabled={layout.loadingTradingview}
        >
          <Stack
            direction={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <>
              <Box height="24px">
                {(() => {
                  const marketInfo = symbolsList.find(
                    (s) => s.symbolLabel === activeSymbol.symbol
                  );
                  return (
                    <DefaultCoinChart
                      marketStatus={
                        marketInfo?.marketStatus as "OPEN" | "CLOSED"
                      }
                      name={activeSymbol.symbol}
                      image={activeSymbol.image}
                      ping={true}
                    />
                  );
                })()}
              </Box>
              {!isMobile && (
                <Typography variant="body2" color={"#AAA"}>
                  {activeSymbol?.type?.charAt(0).toUpperCase() +
                    activeSymbol?.type?.slice(1).toLowerCase()}
                </Typography>
              )}
            </>
          </Stack>
        </Button>
      )}
      {!isMobile &&
        selectedMenuSymbols.map((item, index) => {
          const length = selectedMenuSymbols.length;
          const symbolActive = symbols.find(
            (s) => s.name === correctSymbol(item.symbol)
          );
          const selectedSymbol = symbolActive?.name === activeSymbol.name;

          return (
            <Button
              key={index}
              variant="contained"
              style={{
                position: "relative",
                backgroundColor: layout.loadingTradingview
                  ? "#0b11159c" // Cor de desabilitado
                  : selectedSymbol
                  ? "#01160f"
                  : "#0B1115",
                height: "3.5rem",
                borderRadius: "8px",
                marginLeft: "12px",
                ...(selectedSymbol && {
                  border: "1px solid rgb(19 149 108)",
                }),
              }}
              sx={{
                px: isMobile ? 1 : undefined,
                height: isMobile ? 48 : undefined,
              }}
              onClick={(evt) => {
                if (selectedSymbol) {
                  handleClick(evt);
                } else {
                  handleSwitchSymbol(item);
                }
              }}
              endIcon={selectedSymbol ? <KeyboardArrowDownIcon /> : null}
              disabled={layout.loadingTradingview}
            >
              {length > 1 && (
                <Box
                  sx={{
                    color: "#606f79",
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    "&:hover": {
                      color: "#CCC",
                    },
                  }}
                  onClick={(e) => handleCloseSymbolTab(e, item)}
                >
                  <IoMdClose size={14} />
                </Box>
              )}
              <Stack
                direction={"column"}
                justifyContent="flex-start"
                alignItems={"flex-start"}
              >
                <Box height="24px">
                  <DefaultCoinChart
                    marketStatus={item.marketStatus as "OPEN" | "CLOSED"}
                    name={symbolActive.symbol}
                    image={symbolActive.image}
                    ping={true}
                  />
                </Box>
                <Typography variant="body2" pl="8px" color={"#AAA"}>
                  {item.symbolType.charAt(0).toUpperCase() +
                    item.symbolType.slice(1).toLowerCase()}
                </Typography>
              </Stack>
            </Button>
          );
        })}
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box className="content">
          <Box className="left-side">
            <ul
              className="assets-options"
              style={{ justifyContent: isMobile ? "space-around" : "center" }}
            >
              <li
                className={`option ${
                  currentSymbolType === "FAVORITE" && "active"
                }`}
                onClick={() => setCurrentSymbolType("FAVORITE")}
              >
                <IoMdStar size={30} /> {!isMobile && t("favorites")}
              </li>
              <li
                className={`option ${
                  currentSymbolType === "CRYPTO" && "active"
                }`}
                onClick={() => setCurrentSymbolType("CRYPTO")}
              >
                <SiBitcoinsv size={24} /> {!isMobile && "Cripto"}
              </li>
              <li
                className={`option ${
                  currentSymbolType === "FOREX" && "active"
                }`}
                onClick={() => setCurrentSymbolType("FOREX")}
              >
                <BsCurrencyExchange size={24} /> {!isMobile && "Forex"}
              </li>
              {symbolsList.some((item) => item.symbolType === "OTC") && (
                <li
                  className={`option ${
                    currentSymbolType === "OTC" && "active"
                  }`}
                  onClick={() => setCurrentSymbolType("OTC")}
                >
                  <img
                    src={mobileLogoImage}
                    style={{ width: 22 }}
                    alt="logo da ebinex"
                  />
                  {!isMobile && "OTC"}
                </li>
              )}
            </ul>
          </Box>
          <Box className="right-side">
            <div className="top-content">
              <IoMdClose
                className="btn-close"
                size={20}
                onClick={handleClose}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: "1rem",
                  mb: "1rem",
                }}
              >
                <Tabs
                  indicatorColor="primary"
                  onChange={(e, value) => setCurrentTab(value)}
                  textColor="primary"
                  value={currentTab}
                >
                  <Tab
                    iconPosition="start"
                    label={t("new_options")}
                    value="OPTION"
                  />

                  <Tab
                    iconPosition="start"
                    label={t("retraction")}
                    value="RETRACTION_ENDTIME"
                  />
                </Tabs>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#AAA",
                    paddingBottom: "0.5rem",
                    alignContent: "center",
                  }}
                >
                  {filteredRows.length
                    ? `${t(isMobile ? "total" : "assets-total")}: ${
                        filteredRows.length
                      } `
                    : ""}
                </Typography>
              </Box>

              <TextField
                variant="outlined"
                placeholder={t("search-asset")}
                value={searchText}
                onChange={handleSearch}
                className="search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#AAA" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="body-content">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "symbol"}
                          direction={orderBy === "symbol" ? order : "asc"}
                          onClick={() => handleRequestSort("symbol")}
                        >
                          {t("symbol")}
                        </TableSortLabel>
                      </TableCell>
                      {!isMobile && (
                        <>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "changes"}
                              direction={orderBy === "changes" ? order : "asc"}
                              onClick={() => handleRequestSort("changes")}
                            >
                              {t("changes-24h")}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === "profit"}
                              direction={orderBy === "profit" ? order : "asc"}
                              onClick={() => handleRequestSort("profit")}
                            >
                              {t("profit")}
                            </TableSortLabel>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedRows.map((item) => (
                      <TableRow
                        key={item.symbol}
                        className="symbols-table-row"
                        onClick={() => handleSwitchSymbol(item, true)}
                      >
                        <TableCell>
                          {symbols.find((s) => s.name === item.symbol) && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "row",
                                gap: isMobile ? "2rem" : null,
                                justifyContent: isMobile
                                  ? "flex-start"
                                  : "space-between",
                                alignIitems: "center",
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <img
                                  src={
                                    symbols.find((s) => s.name === item.symbol)
                                      ?.image
                                  }
                                  style={{
                                    width: 40,
                                    height: 40,
                                    marginRight: 5,
                                  }}
                                  alt={`${new Date().getTime()}-image`}
                                />
                                {item.symbolLabel}
                              </Box>
                              {isMobile && (
                                <Box className="mobile-wrappar">
                                  <Box>
                                    <Typography
                                      color={"#AAA"}
                                      variant="caption"
                                    >
                                      {t("changes-24h")}
                                    </Typography>
                                    <Typography
                                      color={"#FFF"}
                                      variant="caption"
                                    >
                                      {" "}
                                      {item.hrs24PercentualChange}%
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      color={"#AAA"}
                                      variant="caption"
                                    >
                                      {t("profit")}
                                    </Typography>

                                    <Typography
                                      component="span"
                                      style={{
                                        padding: "0.1rem 0.6rem",
                                        borderRadius: "4px",
                                      }}
                                      sx={
                                        getPayout(item) > 92
                                          ? {
                                              color: "#01DB97",
                                            }
                                          : {
                                              ml: 0.2,
                                            }
                                      }
                                    >
                                      {getPayout(item) > 92 && (
                                        <FaBolt
                                          size={10}
                                          color="#46d3a7"
                                          style={{ marginRight: 2 }}
                                        />
                                      )}
                                      {getPayout(item)}%
                                    </Typography>
                                  </Box>
                                </Box>
                              )}

                              {isMobile && (
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    right: "0.875rem",
                                  }}
                                  onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleFavorite(item.symbol, !item.favorite);
                                  }}
                                >
                                  {item.favorite ? (
                                    <IoMdStar size={24} color="#46d3a7" />
                                  ) : (
                                    <IoIosStarOutline
                                      size={24}
                                      color="#414754"
                                    />
                                  )}
                                </IconButton>
                              )}
                            </Box>
                          )}
                        </TableCell>

                        {!isMobile && (
                          <>
                            <TableCell>{item.hrs24PercentualChange}%</TableCell>
                            <TableCell className="lucro-cell">
                              <Typography
                                component="span"
                                sx={
                                  getPayout(item) > 92
                                    ? {
                                        backgroundColor: "#01DB9721",
                                        border: "1px solid #154737",
                                        color: "#01DB97",
                                      }
                                    : {
                                        ml: 1.1,
                                      }
                                }
                              >
                                {getPayout(item) > 92 && (
                                  <FaBolt
                                    size={10}
                                    color="#46d3a7"
                                    style={{ marginRight: 2 }}
                                  />
                                )}
                                {getPayout(item)}%
                              </Typography>
                            </TableCell>
                            <TableCell
                              className="favorite-cell"
                              onClick={(evt) => {
                                evt.stopPropagation();
                                handleFavorite(item.symbol, !item.favorite);
                              }}
                            >
                              {item.favorite ? (
                                <IoMdStar size={24} color="#46d3a7" />
                              ) : (
                                <IoIosStarOutline size={24} color="#414754" />
                              )}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Box>
      </StyledMenu>
    </>
  );
}
